#contactform {
	width: 100%;
}

#contactform .input-container {
	width: 100%;
	overflow: hidden;
}

#inputmail, #inputname {
	width: 45%;
}

#inputname {
	margin-right: 5%;
}

#inputmail {
	float: right;
}

#contactform .input-container textarea {
	width: 100%;
}