/* .App {
  text-align: center;
} */

.App-logo {

	height: 75px;
	width: auto;
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
	pointer-events: none;
	display: block;

}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
} */

/* .App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
} */

.App-link {
	color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
	0% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(10px);
	}

	100% {
		transform: translateY(0px);
	}
}

a:hover {
	font-weight: bold;
	/* color:#85cbee; */
}

/*------------ CONTENTS ------------*/
#contents {
	padding-bottom: 50px;
	padding-top: 60px
}


#contents img {
	border: 1px solid rgba(231, 231, 231, 0.8);
}

h1 {

	color: #052AFC;
	font: 29px/30px Verdana, Times, serif;
	margin: 10px 150px 20px;
	text-transform: uppercase;
}

h2 {

	color: #052AFC;
	font: 24px Verdana, Times, serif;
	margin: 10px 50px 20px;
	text-transform: uppercase;

}

h3 {
	
	color: #052AFC;
	font: 20px Verdana, Times, serif;
    margin: 10px 50px 20px;
    text-transform: uppercase;
    
}

h4 {
	
	color: #052AFC;
	font-family: Verdana, Times, serif;
    margin: 10px 50px 20px;
    text-transform: uppercase;
    
}

p, .form, video {

	color: #707070;
	font: 14px/24px Verdana, Helvetica, sans-serif;
	margin: 0 70px 24px;
	text-decoration: none;

}

video {
	width: 80%;
}

#headline {
	margin: 10px 50px 20px;
	width: 100%;
	display: table;
}

.headlinetext {
	display: table-cell;
	box-sizing: border-box; 
	width: 49%; 
	vertical-align: middle;
}

.headlinepic {
	display: table-cell;
	box-sizing: border-box; 
	width: 49%;
}

/*------------ FOOTER ------------*/
#footer {

	background: #FFFFFF;

}

#footer .section {

	float: left;
	min-height: 146px;
	width: 259px;
	padding: 30px;

}

#footer .section h4 {
	color: #868686;
	font: bold 12px/24px Verdana, Helvetica, sans-serif;
	margin: 0;
	text-transform: uppercase;
}

#footnote p {
	color: #868686;
	font: 11px/30px Verdana, Helvetica, sans-serif;
	margin: 0;
	padding-right: 10px;
	text-align: right;
	text-transform: uppercase;
}

@media screen and (min-width: 900px) {
    
    .App-logo {
		height: 100px;
    }
    
    #headline {
		margin: 10px 150px 20px;
    }
    
    h2, h3, h4 {
    	margin: 10px 150px 20px;	
    }
    
    p, .form, video {
		margin: 0 170px 24px;
	}
    
}