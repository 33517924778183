/** navigation **/
label.hamburg { 

	display: block;
	background: #052AFC; 
	width: 75px; 
	height: 50px; 
	position: relative; 
	border-radius: 4px;
 
}

input#hamburg {display:none}

.line { 

	position: absolute; 
	left:10px;
	height: 4px; width: 55px; 
	background: #fff; border-radius: 2px;
	display: block; 
	transition: 0.5s; 
	transform-origin: center;
 
}

.line:nth-child(1) { top: 12px; }
.line:nth-child(2) { top: 24px; }
.line:nth-child(3) { top: 36px; }

#hamburg:checked + .hamburg .line:nth-child(1){
	transform: translateY(12px) rotate(-45deg);
}

#hamburg:checked + .hamburg .line:nth-child(2){
	opacity:0;
}

#hamburg:checked + .hamburg .line:nth-child(3){
	transform: translateY(-12px) rotate(45deg);
}

.navigation {

	display: none;
	list-style: none;
	margin: 0;
	padding: 0;
	/* padding-top: 5px;
	padding-bottom: 5px; */
	background-color: #052AFC;
	overflow: hidden;
        
}

.navvisible {
	display: block;
}

.navigation li {
        
	margin-left: 20px;
	position: relative;
	padding: 5px;
        
}

.navigation li:hover {
	background-color: #052AFC;
}

.navigation li > a, .navigation li {

	/* color: #C7C7C7; */
    color: white;
    font: bold 20px/24px Verdana, sans-serif ;
    text-decoration: none;
    text-shadow: 1px 1px #3D3D3D;
    text-transform: uppercase;
}

.navigation li.active > a, .navigation > a:hover {
	
	/* color: #85cbee; */
	color: white;
	
}

.navigation a:hover {
	/* color: #85cbee; */
    color: white;
}

.linkbutton {
	
	color: white;
	font: bold 20px/24px Verdana, sans-serif ;
    text-decoration: none;
    text-shadow: 1px 1px #3D3D3D;
	text-transform: uppercase;
	background-color: #052AFC;
	border: 0;
	padding: 0;
	cursor: pointer;
	
}

.dropdownnavigation {
	list-style: none;
}

/* For desktop phones: */
@media only screen and (min-width: 1000px) {
	
	label.hamburg {
		display: none;
	}
	
	.navigation {
		
		display: block;
		overflow: visible;
		
	}
	
	.navigation li {
		float: left;
	}
	
	.navcontainer {
		
		margin-top: 30px;
		background-color: #052AFC;
		overflow: visible;
		display: inline-block;
		width: 100%;

	}
	
	.dropdownnavigation {
		
		z-index: 1;
		position: absolute;
		padding-left: 0px;
		left: 0px;
		overflow: visible;
		background-color: #052AFC;
		
	}
	
}