.teachertranslationtable {
	
	color: #707070;
	font: 14px/24px Verdana, Helvetica, sans-serif;
	margin: 0 170px 24px;
	
}

.teachertranslationtable tbody input {
	width: 100%;
}

.teachertranslationtable tbody tr td {

	width: 32%;
	border-bottom: thick solid #707070;

}

.teachertranslationtable tbody tr td textarea {
	width: 100%;
}