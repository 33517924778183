.usersettingtable{
	
	color: #707070;
    font: 14px/24px Verdana, Helvetica, sans-serif;
    margin: 0 170px 24px;
    text-decoration:none;
	
}

.userSettingsBtn{
	
	color: white;
	background-color: #052AFC;
	border: 0;
	padding-left: 5px;
	padding-right: 5px;
	
}