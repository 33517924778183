.listul {
	
	font: 14px/24px Verdana, Helvetica, sans-serif;
    margin: 0 170px 24px;
	
}

.saveNewBtn{
	
	color: white;
	background-color: #052AFC;
	border: 0;
	margin-left: 10px;
	padding-left: 5px;
	padding-right: 5px;
	
}