.goals-container {
	display: flex;
	flex-direction: column;
	gap: 20px;
	color: #707070;
	font: 14px/24px Verdana, Helvetica, sans-serif;
	margin: 0 70px 24px;
	text-decoration: none;
}

.goal-section {
	display: flex;
	align-items: flex-start;
	gap: 20px;
}

.goal-section ul{
	list-style: inside
}

.goal-image-container {
	min-width: 100px;
	max-width: 100px;
}

.goal-image {
	width: 200px;
	height: auto;
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

.goal-list {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

@media screen and (min-width: 900px) {
	
	.goals-container {
		margin: 0 170px 24px;
	}
	
	.goal-image-container {
		min-width: 200px;
		max-width: 200px;
	}
}